import { CheckBox } from "components/base";
import odooIcon from "assets/odoo-icon.svg";
import { FiEdit } from "react-icons/fi";
import CharlesButton from "components/charles/base";
import { currencySymbol } from "pages/price/PriceWithCurrency";
import moment from "moment";

const ProductRow = ({ product, selected, onSelectProduct, editProduct }) => {
  return (
    <tr
      className={`border-t dark:border-gray-700 py-3
               ${selected ? " bg-green-50 dark:bg-green-900 dark:bg-opacity-50" : ""}
               ${product.synced ? "" : " text-red-600"}
         `}
    >
      <td className="flex items-center px-4 space-x-4">
        <CheckBox checked={selected} onChange={onSelectProduct} />
        <div className="flex items-center space-x-2">
          {product.odooId ? (
            <img
              className="mr-2"
              style={{ height: 16 }}
              src={odooIcon}
              alt="odoo product"
            />
          ) : null}
          <div>
            [{product.number}] {product.name}
          </div>

          {product.children.length === 0 && (
            <div className="flex items-center ml-4">
              <CharlesButton onClick={editProduct}>
                <FiEdit />
              </CharlesButton>
            </div>
          )}
        </div>
      </td>
      <td className="text-right">
        {product.prices.map((i, index) => (
          <div key={index}>{i.minQty}</div>
        ))}
      </td>
      <td className="text-right">
        {product.prices.map((i, index) => (
          <div key={index}>
            {currencySymbol(i.currency)} {i.price}
          </div>
        ))}
      </td>
      <td className="text-right">
        {product.odooPrices.map((i, index) => (
          <div key={index}>
            {currencySymbol(i.currency_id[1])} {i.price}
          </div>
        ))}
      </td>
      <td className="text-right px-4">
        {product.supplierUpdatedAt
          ? moment(product.supplierUpdatedAt).format("YYYY-MM-DD")
          : "-"}
      </td>
    </tr>
  );
};

export default ProductRow;
