import { gql } from "@apollo/client";
import { FRAGMENT_PRODUCT_PRICE } from "graphql/fragments";

export const FETCH_PRODUCT_LINE_DETAIL = gql`
  query FETCH_PRODUCT_LINE_DETAIL($id: ID!) {
    productLine(id: $id) {
      id
      name
      mainImage
      products: productsForCost {
        id
        odooId
        number
        name
        isActive
        moq
        quantityPerCarton
        outerCartonCbm
        preferedSupplier {
          id
          name
          odooId
        }
        defaultQty
        itemsPerSet
        prices {
          ...productPrice
        }
        children {
          id
          product {
            id
            name
            number
            odooId
            moq
            defaultSupplier {
              id
              name
              nickName
            }
            prices {
              ...productPrice
            }
            archivedAt
          }
          qty
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_PRICE}
`;

export const FETCH_PRODUCT_PRICE_DETAIL = gql`
  query FETCH_PRODUCT_PRICE_DETAIL($id: ID!) {
    product(id: $id) {
      id
      odooId
      name
      number
      moq
      defaultSupplier {
        id
        name
        nickName
      }
      children {
        id
      }
      prices {
        ...productPrice
      }
      archivedAt
    }
  }
  ${FRAGMENT_PRODUCT_PRICE}
`;

export const SAVE_PRODUCT_PRICE = gql`
  mutation SAVE_PRODUCT_PRICE(
    $id: ID
    $factoryId: Int
    $productId: ID
    $minQty: Int
    $price: Float
    $currency: String
    $avaliableFromMonth: Int
    $avaliableToMonth: Int
    $supplierUpdatedAt: Date
  ) {
    saveProductPrice(
      id: $id
      factoryId: $factoryId
      productId: $productId
      minQty: $minQty
      price: $price
      currency: $currency
      avaliableFromMonth: $avaliableFromMonth
      avaliableToMonth: $avaliableToMonth
      supplierUpdatedAt: $supplierUpdatedAt
    ) {
      productPrice {
        ...productPrice
      }
      product {
        id
        defaultSupplier {
          id
          name
        }
        prices {
          ...productPrice
        }
      }
      effectedProducts {
        id
        odooId
      }
    }
  }
  ${FRAGMENT_PRODUCT_PRICE}
`;

export const ARCHIVE_PRODUCT_PRICE = gql`
  mutation ARCHIVE_PRODUCT_PRICE(
    $id: ID!
    $archivedCode: String!
    $archivedNotes: String
    $newPrice: Float
    $newPriceEffectiveDate: Date
  ) {
    saveProductPrice(
      id: $id
      archivedCode: $archivedCode
      archivedNotes: $archivedNotes
      newPrice: $newPrice
      newPriceEffectiveDate: $newPriceEffectiveDate
    ) {
      productPrice {
        id
        factory {
          id
          name
          nickName
          costType
          odooId
        }
        minQty
        price
        currency
        supplierUpdatedAt
        archivedAt
        archivedCode
        archivedNotes
        updatedAt
        avaliableFromMonth
        avaliableToMonth
      }
      effectedProducts {
        id
        odooId
      }
    }
  }
`;

export const UNARCHIVE_COMPONENT_PRICE = gql`
  mutation UNARCHIVE_COMPONENT_PRICE($id: ID) {
    saveProductPrice(id: $id, unarchive: true) {
      productPrice {
        id
        archivedAt
        archivedCode
        archivedNotes
      }
      effectedProducts {
        id
        odooId
      }
    }
  }
`;

export const SAVE_PRODUCT_SALES_PRICE = gql`
  mutation SAVE_PRODUCT_SALES_PRICE(
    $productId: ID!
    $priceListId: ID!
    $price: Float!
  ) {
    saveProductSalesPrice(
      productId: $productId
      priceListId: $priceListId
      price: $price
    ) {
      product {
        id
        incSalesPrice {
          id
          price
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_PRODUCT_PRICE = gql`
  mutation DELETE_PRODUCT_PRICE($id: ID!) {
    saveProductPrice(id: $id, delete: true) {
      productPrice {
        ...productPrice
      }
      product {
        id
        defaultSupplier {
          id
          name
        }
        prices {
          ...productPrice
        }
      }
      effectedProducts {
        id
        odooId
      }
    }
  }
  ${FRAGMENT_PRODUCT_PRICE}
`;
