import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams, useSearchParams } from "react-router-dom";
import Spinner, { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { CheckBox, NavigationBar } from "components/base";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import http from "utils/http";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import { currencySymbol } from "pages/price/PriceWithCurrency";
import { Select } from "components/Form";
import {
  FETCH_FACTORY_DETAIL,
  FETCH_ODOO_PRODUCT_PRICES,
  FETCH_PRODUCT_PRICES,
} from "./graphql";
import BatchUpdateView from "./BatchUpdateView";
import ProductPricesView from "pages/price/ProductPricesView";
import BatchSyncPriceToOdooView from "../BatchSyncPriceToOdooView";
import ProductRow from "./ProductRow";

const SupplierPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_FACTORY_DETAIL, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  return <ProductsView factory={data.factory} />;
};

const ProductsView = ({ factory }) => {
  const productIds = factory.products.map((product) => product.id);
  const odooProductIds = factory.products
    .filter((i) => i.odooId)
    .map((product) => product.odooId);

  const { loading, error, data } = useQuery(FETCH_PRODUCT_PRICES, {
    variables: { productIds, supplierId: factory.id },
  });
  const fetchOdooProductPrices = useQuery(FETCH_ODOO_PRODUCT_PRICES, {
    variables: { odooProductIds },
  });

  const [searchParams, setSearchParams] = useSearchParams({
    q: "",
    filter: "ALL",
  });
  const q = searchParams.get("q");
  const filter = searchParams.get("filter");

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [isExporting, setIsExporting] = useState(false);

  const modal = useModals();
  const editProductPriceModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const odooProductPrices = fetchOdooProductPrices.data?.odooProductPrices
    ? JSON.parse(fetchOdooProductPrices.data?.odooProductPrices)
    : [];

  const products = factory.products
    .filter((i) => searchByProp(i, ["number"], q))
    .sort((a, b) => (a.number > b.number ? 1 : -1))
    .map((product) => {
      const prices = data.productPrices.results.filter(
        (i) => i.product.id === product.id,
      );
      const supplierUpdatedAt = prices.reduce((acc, i) => {
        if (!acc) return i.supplierUpdatedAt;
        return acc > i.supplierUpdatedAt ? acc : i.supplierUpdatedAt;
      }, null);

      const odooPrices = odooProductPrices.filter(
        (i) =>
          i.product_id[0] === product.odooId &&
          i.partner_id[0] === factory.odooId,
      );

      const computedPrices = prices.reduce((res, x) => {
        const odooPrice = odooPrices
          ? odooPrices.find((op) => op.min_qty === x.minQty)
          : null;
        const wis = `${currencySymbol(x.currency)} ${x.price}`;
        const odoo = odooPrice
          ? `${currencySymbol(odooPrice.currency_id[1])} ${odooPrice.price}`
          : null;
        return [...res, { qty: x.minQty, wis, odoo, synced: wis === odoo }];
      }, []);
      const synced = product.odooId
        ? computedPrices.every((i) => i.synced)
        : true;

      return { ...product, prices, supplierUpdatedAt, odooPrices, synced };
    })
    .filter((i) => (filter === "UNSYNCED" ? !i.synced : true));

  function batchUpdate() {
    modal.present({
      title: "Batch Update",
      children: (
        <BatchUpdateView
          factoryId={factory.id}
          hide={modal.hide}
          products={products.filter((i) => selectedProductIds.includes(i.id))}
        />
      ),
    });
  }

  function tryBatchSyncPriceToOdoo() {
    modal.present({
      title: "Batch Sync Price to Odoo",
      children: (
        <BatchSyncPriceToOdooView
          products={products.filter((i) => selectedProductIds.includes(i.id))}
          hide={modal.hide}
        />
      ),
    });
  }

  function editProductPrice(product) {
    editProductPriceModal.present({
      title: "Edit Price",
      subtitle: `[${product.number}] ${product.name}`,
      maxWidth: "max-w-6xl",
      children: <ProductPricesView id={product.id} currentFactory={factory} />,
    });
  }

  function exportPdf() {
    setIsExporting(true);
    http
      .post(
        "/export/supplier-cost/pdf/",
        {
          id: factory.id,
          product_ids: selectedProductIds,
        },
        {
          baseURL: process.env.REACT_APP_SERVER_BASE_URL,
          timeout: 60000,
          responseType: "blob",
          withCredentials: true,
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${factory.name}_PRICE_LIST.pdf`);
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
      })
      .catch((error) => {
        alert(`Oops! ${error}`);
        setIsExporting(false);
      });
  }

  return (
    <>
      <NavigationBar
        title={factory.name}
        backTo="/price/suppliers"
        rightButtons={<CharlesButton>Quotation Files</CharlesButton>}
      />

      <>
        <div className="flex items-center space-x-4 pt-6 px-6">
          <div className="flex-1">
            <SearchBar
              placeholder="Search by item number"
              value={q}
              onChange={(q) => setSearchParams({ q, filter })}
              autoFocus
            />
          </div>
          <div>
            <Select
              value={filter}
              onChange={(e) => setSearchParams({ filter: e.target.value, q })}
            >
              <option value="0">All</option>
              <option value="UNSYNCED">Unsynced</option>
            </Select>
          </div>
          {selectedProductIds.length > 0 ? (
            <>
              <CharlesButton onClick={batchUpdate}>Batch Update</CharlesButton>
              <CharlesButton
                className="text-purple-600"
                onClick={tryBatchSyncPriceToOdoo}
              >
                Batch Sync to Odoo
              </CharlesButton>
              <CharlesButton onClick={exportPdf} loading={isExporting}>
                Export
              </CharlesButton>
            </>
          ) : null}
        </div>

        <div className="card rounded-2xl p-0 overflow-auto shadow-md mx-6 my-6 flex-1">
          <div className="h-full relative dark:text-gray-400">
            <table>
              <thead>
                <tr className="sticky top-0 bg-white backdrop-blur bg-opacity-80 shadow-sm dark:bg-gray-800">
                  <th className="py-3 px-4">
                    <div className="flex items-center w-3/5">
                      <CheckBox
                        checked={selectedProductIds.length === products.length}
                        onChange={() =>
                          selectedProductIds.length === products.length
                            ? setSelectedProductIds([])
                            : setSelectedProductIds(products.map((i) => i.id))
                        }
                      />
                      <div className="text-left px-4">
                        Products ({selectedProductIds.length}/{products.length})
                      </div>
                    </div>
                  </th>
                  <th className="text-right">QTY</th>
                  <th className="text-right">Cost</th>
                  <th className="text-right text-purple-600">
                    <div className="flex space-x-2 items-center justify-end">
                      <div
                        className={`relative
                            ${
                              fetchOdooProductPrices.loading
                                ? "opacity-50"
                                : "opacity-0"
                            }`}
                      >
                        <InlineSpinner text={null} size={14} />
                      </div>
                      <span>Odoo</span>
                    </div>
                  </th>
                  <th className="text-right pr-4">Supplier Updated</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product, index) => (
                  <ProductRow
                    key={index}
                    product={product}
                    selected={selectedProductIds.includes(product.id)}
                    onSelectProduct={() => {
                      setSelectedProductIds((prev) =>
                        prev.includes(product.id)
                          ? prev.filter((i) => i !== product.id)
                          : [...prev, product.id],
                      );
                    }}
                    editProduct={() => editProductPrice(product)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
  );
};

export default SupplierPage;
