import { gql } from "@apollo/client";

export const FETCH_FACTORY_DETAIL = gql`
  query FETCH_FACTORY_DETAIL($id: ID!) {
    factory(id: $id) {
      id
      odooId
      name
      products {
        id
        number
        name
        odooId
        children {
          id
        }
      }
    }
  }
`;

export const FETCH_PRODUCT_PRICES = gql`
  query FETCH_PRODUCT_PRICES($productIds: [ID], $supplierId: ID) {
    productPrices(
      productIds: $productIds
      supplierId: $supplierId
      isValid: true
    ) {
      total
      results {
        id
        minQty
        price
        currency
        supplierUpdatedAt
        factory {
          id
        }
        product {
          id
        }
      }
    }
  }
`;

export const FETCH_ODOO_PRODUCT_PRICES = gql`
  query FETCH_ODOO_PRODUCT_PRICES($odooProductIds: [Int]) {
    odooProductPrices(odooProductIds: $odooProductIds)
  }
`;

export const BATCH_UPDATE_COST_2 = gql`
  mutation BATCH_UPDATE_COST_2(
    $factoryId: ID!
    $productIds: [ID]
    $notes: String!
    $code: String!
    $currency: String
    $effectiveDate: Date!
    $updatedPrices: [UpdatedPriceType]
    $formula: String
    $formulaValue: Float
    $decimalPlaces: Int
    $levels: [BatchUpdatePriceLevelInputType]
    $syncToOdoo: Boolean
  ) {
    batchUpdatePrices(
      factoryId: $factoryId
      productIds: $productIds
      currency: $currency
      notes: $notes
      code: $code
      effectiveDate: $effectiveDate
      updatedPrices: $updatedPrices
      formula: $formula
      formulaValue: $formulaValue
      decimalPlaces: $decimalPlaces
      levels: $levels
      syncToOdoo: $syncToOdoo
    ) {
      factory {
        id
      }
    }
  }
`;

export const FETCH_UPDATED_PRICES = gql`
  query FETCH_UPDATED_PRICES($productIds: [ID], $odooProductIds: [Int]) {
    productPrices(productIds: $productIds, isValid: true) {
      total
      results {
        id
        minQty
        price
        currency
        supplierUpdatedAt
        factory {
          id
        }
        product {
          id
        }
      }
    }
    odooProductPrices(odooProductIds: $odooProductIds)
  }
`;
